







































































































































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { ManagerAgente } from "../../../../types/Manager";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../../../services/request";
import TransferDialog from "../../../chat/TransferDialog.vue";
import CloseDialog from "../../../../components/CloseDialog.vue";
import ActiveDialog from "../../../../components/ActiveDialog.vue";
import { translate } from "../../../../plugins/i18n/index";
import { mapGetters } from "vuex";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import BreaksDialog from "../../../../components/BreaksDialog.vue";
import LogoutDialog from "../../../../components/LogoutDialog.vue";
import ManagerChat from "./ManagerChat.vue";
import { EventBus } from "../../../../services/eventBus";
import {IUser} from "../../../../types/User";
import { IBreaks } from "../../../../types/manager/manager";
import { HeaderItem } from "../../../../types/headeritem";
@Component({
  components: {
    List,
    TransferDialog,
    BaseBreadcrumb,
    ActiveDialog,
    CloseDialog,
    PageHeader,
    BreaksDialog,
    ManagerChat,
    LogoutDialog,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Manager extends Request {
  title: string = `${translate("manager")}`;
  listAgente: ManagerAgente[] = [];
  getUser!: () => IUser;
  user:IUser= {};
  userAgentList: any = [];
  breaks: IBreaks[] = [];
  expanded = [];
  search: string = "";
  itemsPerPage:number = 10;

  attendencyToTransferDialog: IBreaks = {};
  headerAgente: HeaderItem[] = [
    { text: `${translate("name")}`, align: "start", value: "user.firstName" },
    { text: `${translate("email")}`, align: "center", value: "user.email" },
    { text: `${translate("status")}`, align: "center", value: "active" },
    { text: `${translate("button.Logged")}`, align: "center", value: "status" },
    { text: `${translate("medals")}`, align: "center", value: "medals" },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
    { text: "", value: "data-table-expand", align: "left", width: "5%" },
  ];
  header: HeaderItem[] = [
    {
      text: `${translate("client")}`,
      align: "start",
      value: "contact.firstName",
    },
    {
      text: `${translate("protocol")}`,
      align: "center",
      value: "attendency.protocolNumber",
    },
    {
      text: `${translate("channelSingular")}`,
      align: "center",
      value: "attendency.channelType",
    },

    // {
    //   text: `${translate("lastMessage")}`,
    //   align: "center",
    //   value: "lastMessage",
    // },
    // { text: `${translate("protocol")}`, align: "center", value: "protocol" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  type: string = "";
  pageHeadeAgente: TPageHeader = {
    mainIcon: "mdi-account-circle-outline",
    titlePage: `${translate("agentList")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  async mounted() {
    await this.loadData();
  }
  async loadData() {
    this.user = this.getUser();
    const agents = await this.get(this.paths.userAgentPath);
    this.userAgentList = agents[0];

    this.userAgentList.map((item) => {
      item.medals = true;
      if (item.active === true) {
        item.medalColor = "#D4af37";
      }
    });

    const result = await this.get(this.paths.breakPath);
    this.breaks = result[0];
  }
  async loadDetails({ item }) {
    this.attendencyToTransferDialog = item;
    let attendencyQueueList;

    if (item.queueUserAgents && item.queueUserAgents[0]) {
      attendencyQueueList = await this.get(
        this.paths.attendencyQueueUserAgent +
          `?userAgentId=${item.queueUserAgents[0].userAgentId}&skip=${1}&take=${
            this.itemsPerPage
          }`
      );
    }

    if (attendencyQueueList && attendencyQueueList[0]) {
      this.listAgente = attendencyQueueList[0].filter(
        (conversation) =>
          item.queueUserAgents[0].userAgentId === conversation.userAgentId &&
          !conversation.endDate
      );
    } else {
      this.listAgente = [];
    }
  }
  getStatusColor(queueUserAgents) {
    let color = "#F90000";
    for (const agent of queueUserAgents) {
      switch (agent.status) {
        case "A":
          color = "#7DF900";
          break;
        case "P":
          color = "#FFFF00";
          break;
        default:
          break;
      }
    }
    return color;
  }
  getStatusText(queueUserAgents) {
    let text = this.$t("button.disconnected");
    for (const agent of queueUserAgents) {
      switch (agent.status) {
        case "A":
          text = this.$t("button.Logged");
          break;
        case "P":
          text = this.$t("paused");
          break;
        default:
          break;
      }
    }
    return text;
  }
  get listAttendency() {
    return this.listAgente;
  }
  disabledLogOut(item) {
    return item?.queueUserAgents[0]?.status == "P" ||
      item?.queueUserAgents[0]?.status == "A"
      ? true
      : false;
  }

  created() {
    EventBus.$on("update-chat", () => {
      this.loadData();
    });
  }
}
