























































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Authorizations extends Request {
  @Prop() readonly manutentionId: number;
  dialog: boolean = false;
  authorizationsList: any = [];
  observation: string = "";
  getUser: any;
  user: any = {};
  userObservationId: number = null;
  headers = [
    {
      text: `${translate("authorizing")}`,
      align: "",
      sortable: false,
      value: "name",
    },
    { text: `${translate("justification")}`, value: "observation" },
    { text: `${translate("status")}`, value: "authorization" },
  ];
  list = [];
  changeStatus(value, index) {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[index].authorization === 0) {
        this.list[index].authorization = value;
      } else if (this.list[index].authorization === 1) {
        this.list[index].authorization = value;
      }
    }
  }
  async mounted()  {
    this.user = this.getUser();
    const authorizations = await this.get(this.paths.userPath);
    this.authorizationsList = authorizations[0];

    const result = await this.get(this.paths.maintenancePath);
    this.list = result[0].find(
      (i) => i.id === this.manutentionId
    ).manutentionAuthorization;

    this.list.map((item) => {
      this.authorizationsList.find((i) => {
        if (item.authorizationUserId === i.id) {
          item.userId = i.id;
          for (let index = 0; index < this.list.length; index++) {
            item.name = i.firstName + " " + i.lastName;
          }
        }
      });
    });
  }
  async onSave() {
    this.list.map(async (item) => {
      const entityAuthorization = {
        manutentionId: this.manutentionId,
        authorizationUserId: item.authorizationUserId,
        authorization: item.authorization,
        observation: item.observation,
      };
      const result = await this.put(
        this.paths.maintenanceAuthorizationPath + item.id,
        entityAuthorization
      );
      this.dialog = false;
      return result;
    });
  }
  getItem(item) {
    this.userObservationId = item.id;
  }
  get disableObservation() {
    return this.user.id != this.userObservationId
  }
}
