var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InputPopup',{attrs:{"header":_vm.header,"itemsForm":_vm.itemsForm,"entity":_vm.entity,"list":_vm.list,"toolbar":true,"save":_vm.onSave,"actionButtons":true,"loadData":_vm.loadData}},[_c('template',{slot:"product"},[_c('v-select',{attrs:{"items":_vm.productList,"label":_vm.$t('product'),"outlined":"","item-text":"name","item-value":"id","return-object":false,"rules":[_vm.$rules.fieldsRequired]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})],1),_c('template',{slot:"date"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.$rules.fieldsRequired],"outlined":"","label":_vm.$t('date'),"prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('template',{slot:"startTime"},[_c('v-menu',{ref:"startTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.startDateTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.startDateTime=$event},"update:return-value":function($event){_vm.startDateTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('startTime'),"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.startDateTime),callback:function ($$v) {_vm.startDateTime=$$v},expression:"startDateTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[(_vm.startDateMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.startTime.save(_vm.startDateTime)}},model:{value:(_vm.startDateTime),callback:function ($$v) {_vm.startDateTime=$$v},expression:"startDateTime"}}):_vm._e()],1)],1),_c('template',{slot:"endTime"},[_c('v-menu',{ref:"endTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.endDateTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.endDateTime=$event},"update:return-value":function($event){_vm.endDateTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('endtTime'),"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.endDateTime),callback:function ($$v) {_vm.endDateTime=$$v},expression:"endDateTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endtDateMenu),callback:function ($$v) {_vm.endtDateMenu=$$v},expression:"endtDateMenu"}},[(_vm.endtDateMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.endTime.save(_vm.endDateTime)}},model:{value:(_vm.endDateTime),callback:function ($$v) {_vm.endDateTime=$$v},expression:"endDateTime"}}):_vm._e()],1)],1),_c('template',{slot:"requester"},[_c('v-text-field',{attrs:{"outlined":"","disabled":"","label":_vm.$t('requester')},model:{value:(_vm.requester),callback:function ($$v) {_vm.requester=$$v},expression:"requester"}})],1),_c('template',{slot:"technicalManager"},[_c('v-select',{attrs:{"items":_vm.technicalManagerList,"label":_vm.$t('technicalManager'),"outlined":"","item-text":"firstName","item-value":"id","return-object":false,"rules":[_vm.$rules.fieldsRequired]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" ")]}}]),model:{value:(_vm.technicalManager),callback:function ($$v) {_vm.technicalManager=$$v},expression:"technicalManager"}})],1),_c('template',{slot:"authorizations"},[_c('v-select',{attrs:{"multiple":"","items":_vm.authorizationsList,"label":_vm.$t('authorizations'),"outlined":"","item-text":"firstName","item-value":"id","return-object":false,"rules":[_vm.$rules.fieldsRequired]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" ")]}}]),model:{value:(_vm.authorizations),callback:function ($$v) {_vm.authorizations=$$v},expression:"authorizations"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }