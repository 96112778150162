












































import { Component, Prop } from "vue-property-decorator";
import PlanConfigInput from "./PlanConfigInput.vue";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { Breadcrumb } from "../../types/breadcrumb";
import { HeaderItem } from "../../types/headeritem";
import { IPlan } from "../../types/Plan";

@Component({
  components: {
    PlanConfigInput,
    List,
    PageHeader,
  },
})
export default class PlanList extends Request {
  @Prop() readonly company: number;
  page = {
    title: `${translate("title.plans")}`,
    icon: null,
  };
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("title.plans")}`,
      disabled: true,
    },
  ];
  dueDate: number = null;
  result: any = [];
  headers: HeaderItem[] = [
    { text: `${translate("name")}`, align: "center", value: "name" },
    { text: `${translate("price")}`, align: "center", value: "price" },
  ];
  currentPlan: string = "";
  pageHeader: TPageHeader = {
    mainIcon: "mdi-notebook",
    titlePage: `${translate("title.plans")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: IPlan[] = [];

  async loadData() {
    this.result = await this.get(
      this.paths.companyProductPlanGetPath + this.company
    );
    if (this.result[0] && this.result[0].plan) {
      this.currentPlan = `${
        translate("currentPlan") + " " + this.result[0].plan.name
      }`;
      this.dueDate = this.result[0].payDate;
    }

    const plans = await this.get(this.paths.heppyInvoicePlans);
    this.list = plans[0];
    console.log(this.list);
    this.list.map((plan) => {
      plan.price = `R$${plan.price}`;
    });
  }
  async mounted() {
    await this.loadData();
  }
}
