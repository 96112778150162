





























































import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import MaintenanceInput from "./MaintenanceInput.vue";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import Authorizations from "./Authorizations.vue";
import {
  ConfirmDelete,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import ChangeStatus from "@/views/maintenance/ChangeStatus.vue";

@Component({
  components: {
    MaintenanceInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
    Authorizations,
    ChangeStatus,
  },
})
export default class MaintenanceList extends Request {
  path = paths.maintenancePath;
  productId: any = this.$route.query.productId;

  title = `${translate("maintenance")}`;
  breadcrumbs = [
    {
      text: `${translate("maintenance")}`,
      disabled: false,
      to: "/maintenance/",
    },
  ];
  customColumns = [
    {
      slotName: "executor.firstName",
      itemValue: "executor.firstName",
    },
    {
      slotName: "requester.firstName",
      itemValue: "requester.firstName",
    },
  ];
  header = [
    { text: `${translate("description")}`, value: "description" },
    {
      text: `${translate("explanation")}`,
      align: "center",
      value: "explanation",
    },
    {
      text: `${translate("ticketMatrix")}`,
      align: "center",
      value: "ticketMatrix",
    },
    {
      text: `${translate("requester")}`,
      align: "center",
      value: "requester.firstName",
    },
    { text: `${translate("risk")}`, align: "center", value: "risk" },
    {
      text: `${translate("executor")}`,
      align: "center",
      value: "executor.firstName",
    },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-wrench",
    titlePage: `${translate("maintenance")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: any[] = [];
  async loadData() {
    const result = await this.get(this.paths.maintenanceListPath);
    this.list = result;
  }
  async mounted()  {
    this.loadData();
  }
}
