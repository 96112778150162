


































































import { Component, Prop } from "vue-property-decorator";
import { IUser } from "../../../types/User";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views/index";
import { InputModel } from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";
import Request from "../../../services/request";
import { translate } from "../../../plugins/i18n/index";

@Component({
  components: {
    InputPopup,
  },
})
export default class TypeUserInput extends Request {
  @Prop() readonly entity: IUser;
  @Prop() readonly list: IUser[];
  confirmPassword: any = null;
  password: any = null;
  profileList: any = [];
  profileId: any = null;
  show1: boolean = false;
  show2: boolean = false;
  companyId = Number(this.$route.query.companyId);
  type = "";
  types = [
    { value: "U", text: `${translate("user")}` },
    { value: "I", text: `${translate("integracao")}` },
  ];
  dialog = false;
  readonly header = `${translate("title.addUser")}`;

  itemsForm: InputModel[] = [
    {
      id: 1,
      col: 6,
      label: `${translate("firstName")}`,
      tag: "v-text-field",
      type: "text",
      model: "firstName",
      required: true,
    },
    {
      id: 2,
      col: 6,
      label: `${translate("lastName")}`,
      tag: "v-text-field",
      type: "text",
      model: "lastName",
      required: true,
    },

    {
      id: 3,
      col: 12,
      label: `${translate("email")}`,
      tag: "v-text-field",
      type: "text",
      model: "email",
      required: true,
    },
    {
      id: 4,
      col: 6,
      slot: "password",
    },
    {
      id: 5,
      col: 6,
      slot: "confirmPassword",
    },
    {
      id: 6,
      col: 6,
      label: `${translate("phone")}`,
      tag: "v-text-field",
      type: "text",
      model: "phone",
      required: true,
    },
    {
      id: 7,
      col: 6,
      slot: "profile",
    },
  ];

  async onSave() {
    this.entity.companyId = this.companyId;
    this.entity.secret = this.password;
    const result = await this.save(this.paths.userSavePath, this.entity);
    this.dialog = false;
    return result;
  }
  async created() {
    const profile = await this.get(this.paths.profilePath, {
      companyId: this.companyId,
    });
    this.profileList = profile[0];
  }
  get missingPassword() {
    return !this.password;
  }
  get missingConfirmPassword() {
    return (
      !!this.password &&
      (!this.confirmPassword || this.password != this.confirmPassword)
    );
  }
  get saveDisabled() {
    return this.missingPassword || this.missingConfirmPassword;
  }
}
