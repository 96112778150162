















































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { mapGetters } from "vuex";
@Component({
  components: {},
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class PlanConfigInput extends Request {
  @Prop() readonly company: any;
  @Prop() readonly loadData: any;
  @Prop() readonly currentPlan: any;
  getUser: any;
  list: any[] = [];
  plans: any = null;
  productId: any = null;
  payDate: any = null;
  dialog: boolean = false;
  user: any = {};
  itemsPerPage = 4;
  async changePlan(planId) {
    const companyProductPlan = {
      companyId: this.company,
      planId: planId,
      productId: this.productId,
      payDate: this.payDate,
      editorUserId: this.user.id,
    };
    const result = await this.put(
      this.paths.companyProductPlanGetPath + this.company,
      companyProductPlan
    );

    this.dialog = false;
    this.loadData();
    return result;
  }
  async mounted() {
    this.user = this.getUser();
    const plans = await this.get(this.paths.heppyInvoicePlans);
    this.list = plans[0];
    this.plans = await this.get(
      this.paths.companyProductPlanGetPath + this.company
    );
    this.plans.map((product) => {
      this.payDate = product.payDate;
      return (this.productId = product.productId);
    });
  }
}
