



































































































































































import { Component, Prop } from "vue-property-decorator";
import { IUser } from "../../../types/User";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views/index";
import Request from "../../../services/request";
import { IProfile } from "../../../types/profile/ProfileType";
import { translate } from "../../../plugins/i18n/index";
import { mapGetters } from "vuex";

@Component({
  components: {
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class TypeUserInput extends Request {
  @Prop() readonly entity: IUser;
  @Prop() readonly list: IUser[];
  @Prop() loadData: () => void;
  @Prop() readonly profileList: IProfile[];
  profileId: number = null;
  show1: boolean = false;
  show2: boolean = false;
  editorUserId: number = null;
  companyId: number = null;
  passwordError: any = "";
  hasMinLength: boolean = null;
  hasUppercase: boolean = null;
  hasLowercase: boolean = null;
  hasLettersAndNumbers: boolean = null;
  hasSpecialCharacter: boolean = null;
  localEntity: any = {};
  getUser: any;
  user: IUser;

  dialog = false;
  readonly header = `${translate("user")}`;
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }
  async onSave() {
    this.localEntity.companyId = this.companyId;
    this.localEntity.editorUserId = this.editorUserId;
    this.localEntity.profiles = [
      {
        profileId: this.profileId,
        scope: "HEP",
      },
    ];

    const result = await this.save(this.paths.userSavePath, this.localEntity);

    // if (result.id) {
    //   const entityUserAgent = {
    //     userId: result.companyUserId ? result.companyUserId : result.id,
    //     profileId: this.profileId,
    //     editorUserId: this.editorUserId,
    //   };
    //   await this.post(this.paths.userAgentPath, entityUserAgent);
    //   this.loadData();
    //   if (!this.localEntity.id) {
    //     this.localEntity = {};
    //   }

    // }

    this.dialog = false;
    this.loadData();
    return result;
  }
  validatePassword() {
    const password = this.localEntity.secret;
    this.hasMinLength = password && password.length >= 8 ? true : false;
    this.hasUppercase = /[A-Z]/.test(password);
    this.hasLowercase = /[a-z]/.test(password);
    this.hasLettersAndNumbers = /[A-Za-z].*\d|\d.*[A-Za-z]/.test(password);
    this.hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (
      this.hasMinLength &&
      this.hasUppercase &&
      this.hasLowercase &&
      this.hasLettersAndNumbers &&
      this.hasSpecialCharacter
    ) {
      this.passwordError = "";
      return false;
    } else {
      this.passwordError = "A senha não atende a todos os critérios.";
      return true;
    }
  }
  async created() {
    this.load();
    this.user = this.getUser();

    this.editorUserId = this.user.id;
    if (this.localEntity && this.localEntity.userProfiles) {
      this.localEntity.userProfiles.map((item) => {
        this.profileId = item.profileId;
      });
    }

    this.companyId = this.user.companyId;
  }
  get activeProfiles() {
    return this.profileList.filter((profile) => profile.active === true);
  }

  get missingPassword() {
    return !this.localEntity.id ? !this.localEntity.secret : null;
  }
  get missingConfirmPassword() {
    return !this.localEntity.id
      ? !!this.localEntity.secret &&
          (!this.localEntity.confirmPassword ||
            this.localEntity.secret != this.localEntity.confirmPassword)
      : null;
  }
  get missingFirstName() {
    return !this.localEntity.firstName;
  }
  get missingEmail() {
    return !this.localEntity.email;
  }
  get missingPhone() {
    return !this.localEntity.phone;
  }
  get missingProfile() {
    return !this.profileId;
  }
  get invalidPassword() {
    return this.validatePassword();
  }
  get saveDisabled() {
    return (
      this.missingPassword ||
      this.missingConfirmPassword ||
      this.missingFirstName ||
      this.missingEmail ||
      this.missingPhone ||
      this.missingProfile ||
      this.invalidPassword
    );
  }
}
