
































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import MaintenanceInput from "./MaintenanceInput.vue";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import FormChangeStatus from "./FormChangeStatus.vue";

@Component({
  components: {
    MaintenanceInput,
    InputPopup,
    FormChangeStatus,
  },
})
export default class ChangeStatus extends Request {
  @Prop() readonly manutentionId: number;
  @Prop() entity: any;
  dialog: boolean = false;
  closeDialog(value) {
    this.dialog = value;
  }
}
