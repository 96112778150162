





































































































import { Component } from "vue-property-decorator";
import { ManagerI, ManagerITwo } from "../../../../types/Manager";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../../../services/request";
import TransferDialog from "../../../chat/TransferDialog.vue";
import CloseDialog from "../../../../components/CloseDialog.vue";
import ActiveDialog from "../../../../components/ActiveDialog.vue";
import { translate } from "../../../../plugins/i18n/index";
import { mapGetters } from "vuex";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import ManagerChat from "./ManagerChat.vue";
import { IQueueData } from "../../../../types/manager/queue";
import { HeaderItem } from "../../../../types/headeritem";
import {IUser} from "../../../../types/User";
@Component({
  components: {
    TransferDialog,
    ActiveDialog,
    CloseDialog,
    PageHeader,
    ManagerChat,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Manager extends Request {
  title: string = `${translate("manager")}`;
  listQueue: ManagerI[] = [];
  search: string = "";
  list: ManagerITwo[] = [];
  expanded = [];
  queueList: IQueueData[] = [];
  attendencyToTransferDialog: IQueueData = {};
  type: string = "";

  getUser!: () => IUser;
  user:IUser= {};
  headerAgente: HeaderItem[] = [
    {
      text: `${translate("client")}`,
      align: "start",
      value: "contact.firstName",
    },
    {
      text: `${translate("channelSingular")}`,
      align: "center",
      value: "attendency.channelType",
    },
    {
      text: `${translate("protocol")}`,
      align: "center",
      value: "attendency.protocolNumber",
    },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "start",
      sortable: false,
      width: "15%",
    },
  ];
  headerQueue: HeaderItem[] = [
    { text: `${translate("queue")}`, align: "start", value: "name" },

    { text: `${translate("status")}`, align: "center", value: "active" },
    {
      text: `${translate("amountServiceBot")}`,
      align: "center",
      value: "counts.botAttendencies",
    },
    {
      text: `${translate("amountServiceAgente")}`,
      align: "center",
      value: "counts.agentAttendencies",
    },
    {
      text: `${translate("amountServiceWaiting")}`,
      align: "center",
      value: "counts.waitingAttendencies",
    },
    {
      text: `${translate("amountServiceAllDay")}`,
      align: "center",
      value: "counts.todayAttendencies",
    },

    { text: "", value: "data-table-expand" },
  ];
  pageHeaderQueue: TPageHeader = {
    mainIcon: "mdi-human-queue",
    titlePage: `${translate("queue")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  async loadData() {
    this.user = this.getUser();

    const counts = await this.get(this.paths.pathMetricsAttendencies);

    const result = await this.get(this.paths.queuePath);

    result[0].forEach((queue) => {
      queue.counts = counts.find((item) => item.queueId == queue.id);
    });

    this.queueList = result[0];
  }
  async mounted() {
    this.loadData();
  }

  async loadDetails({ item }) {
    let queueId;
    this.attendencyToTransferDialog = item;
    if (item.attendencyQueues[0] && item.attendencyQueues[0].queueId) {
      queueId = item.attendencyQueues[0].queueId;
      const result = await this.get(this.paths.attendencyQueue + queueId);

      this.listQueue = result["result"].filter((i) => !i.endDate);
    }
  }
}
