var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trackpad-lock")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary"},[_vm._v(" "+_vm._s(_vm.$t("authorizations"))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.authorization",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","disabled":item.userId != _vm.user.id},on:{"click":function($event){return _vm.changeStatus('mdi-thumb-up' ? 1 : 0, index)}}},[_c('v-icon',{attrs:{"color":"#0558c3"},model:{value:(item.authorization),callback:function ($$v) {_vm.$set(item, "authorization", $$v)},expression:"item.authorization"}},[_vm._v(" "+_vm._s(item.authorization == true ? "mdi-thumb-up" : "mdi-thumb-up-outline"))])],1),_c('v-btn',{attrs:{"disabled":item.userId != _vm.user.id,"icon":""},on:{"click":function($event){return _vm.changeStatus('mdi-thumb-down' ? 0 : 1, index)}}},[_c('v-icon',{attrs:{"color":"#ff0000"},model:{value:(item.authorization),callback:function ($$v) {_vm.$set(item, "authorization", $$v)},expression:"item.authorization"}},[_vm._v(" "+_vm._s(item.authorization == false ? "mdi-thumb-down" : "mdi-thumb-down-outline")+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-2 mb-2"},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","disabled":"","label":_vm.$t('name'),"placeholder":_vm.$t('observation')},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)]}},{key:"item.observation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-2 mb-2"},[_c('v-text-field',{attrs:{"disabled":item.userId != _vm.user.id,"outlined":"","hide-details":"","label":_vm.$t('observation'),"placeholder":_vm.$t('observation')},model:{value:(item.observation),callback:function ($$v) {_vm.$set(item, "observation", $$v)},expression:"item.observation"}})],1)]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t("button.save")))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("button.cancel")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }