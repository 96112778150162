var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"mt-5",attrs:{"items":_vm.statusList,"label":_vm.$t('changeStatus'),"outlined":"","item-text":"description","item-value":"id","return-object":false,"rules":[_vm.$rules.fieldsRequired]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}}]),model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.selectedStatus === 3 || _vm.selectedStatus === 4)?_c('v-textarea',{attrs:{"rules":[_vm.$rules.fieldsRequired],"outlined":"","label":_vm.$t('observation')},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}}):_vm._e()],1),(_vm.selectedStatus === 4)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":_vm.rescheduleLabel},model:{value:(_vm.reschedule),callback:function ($$v) {_vm.reschedule=$$v},expression:"reschedule"}})],1):_vm._e(),(_vm.reschedule === true)?_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.productList,"label":_vm.$t('product'),"outlined":"","item-text":"name","item-value":"id","return-object":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,4286803846),model:{value:(_vm.entity.productId),callback:function ($$v) {_vm.$set(_vm.entity, "productId", $$v)},expression:"entity.productId"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.$rules.fieldsRequired],"outlined":"","label":_vm.$t('date'),"prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,false,818272228),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.startDateTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.startDateTime=$event},"update:return-value":function($event){_vm.startDateTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('startTime'),"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.startDateTime),callback:function ($$v) {_vm.startDateTime=$$v},expression:"startDateTime"}},'v-text-field',attrs,false),on))]}}],null,false,2783760510),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[(_vm.startDateMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.startDateTime)}},model:{value:(_vm.startDateTime),callback:function ($$v) {_vm.startDateTime=$$v},expression:"startDateTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.endDateTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.endDateTime=$event},"update:return-value":function($event){_vm.endDateTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":_vm.$t('endtTime'),"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.endDateTime),callback:function ($$v) {_vm.endDateTime=$$v},expression:"endDateTime"}},'v-text-field',attrs,false),on))]}}],null,false,2361166922),model:{value:(_vm.endtDateMenu),callback:function ($$v) {_vm.endtDateMenu=$$v},expression:"endtDateMenu"}},[(_vm.endtDateMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.endDateTime)}},model:{value:(_vm.endDateTime),callback:function ($$v) {_vm.endDateTime=$$v},expression:"endDateTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('description')},model:{value:(_vm.entity.description),callback:function ($$v) {_vm.$set(_vm.entity, "description", $$v)},expression:"entity.description"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('justification')},model:{value:(_vm.entity.explanation),callback:function ($$v) {_vm.$set(_vm.entity, "explanation", $$v)},expression:"entity.explanation"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('scratchs')},model:{value:(_vm.entity.risk),callback:function ($$v) {_vm.$set(_vm.entity, "risk", $$v)},expression:"entity.risk"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('requester')},model:{value:(_vm.entity.requester),callback:function ($$v) {_vm.$set(_vm.entity, "requester", $$v)},expression:"entity.requester"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.technicalManagerList,"label":_vm.$t('technicalManager'),"outlined":"","item-text":"firstName","item-value":"id","return-object":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" ")]}}],null,false,968996444),model:{value:(_vm.entity.executor),callback:function ($$v) {_vm.$set(_vm.entity, "executor", $$v)},expression:"entity.executor"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('devResponsable'),"outlined":""},model:{value:(_vm.entity.requester),callback:function ($$v) {_vm.$set(_vm.entity, "requester", $$v)},expression:"entity.requester"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('devOs'),"outlined":""},model:{value:(_vm.entity.ascOs),callback:function ($$v) {_vm.$set(_vm.entity, "ascOs", $$v)},expression:"entity.ascOs"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('devOs'),"outlined":""},model:{value:(_vm.entity.devOs),callback:function ($$v) {_vm.$set(_vm.entity, "devOs", $$v)},expression:"entity.devOs"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('ticketMatrix'),"outlined":""},model:{value:(_vm.entity.ticketMatrix),callback:function ($$v) {_vm.$set(_vm.entity, "ticketMatrix", $$v)},expression:"entity.ticketMatrix"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('clientResponsable'),"outlined":""},model:{value:(_vm.entity.clientUser),callback:function ($$v) {_vm.$set(_vm.entity, "clientUser", $$v)},expression:"entity.clientUser"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"multiple":"","items":_vm.authorizationsList,"label":_vm.$t('authorizations'),"outlined":"","item-text":"firstName","item-value":"id","return-object":false},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" ")]}}],null,false,968996444),model:{value:(_vm.authorizations),callback:function ($$v) {_vm.authorizations=$$v},expression:"authorizations"}})],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$t("button.save")))]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$t("button.cancel")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }