var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"header":_vm.pageHeadeAgente}}),_c('v-data-table',{attrs:{"showExpand":true,"headers":_vm.headerAgente,"pageHeaderConfig":_vm.pageHeadeAgente,"items":_vm.userAgentList,"tagActive":"active","single-expand":true,"expanded":_vm.expanded,"item-key":"id","search":_vm.search},on:{"item-expanded":_vm.loadDetails,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-col',{attrs:{"cols":"12"}},[_vm._t("select")],2)]},proxy:true},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":item.active === true ? '#7DF900' : '#F90000',"dark":""}},[_c('span',{staticStyle:{"color":"#000"}},[_vm._v(" "+_vm._s(item.active === true ? _vm.$t("active") : _vm.$t("inactive")))])])]}},{key:"item.medals",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.medalColor}},[_vm._v(" mdi-medal-outline")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"widthBaloon",attrs:{"color":_vm.getStatusColor(item.queueUserAgents),"dark":""}},[_c('span',{staticStyle:{"color":"#000"}},[_vm._v(" "+_vm._s(_vm.getStatusText(item.queueUserAgents)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-app":""}},[_c('BreaksDialog',{attrs:{"isDisabled":_vm.disabledLogOut(item),"loadData":_vm.loadData,"entity":item,"breaks":_vm.breaks,"type":"icon","small":"","title":_vm.$t('transfer'),"user":_vm.user}}),_c('LogoutDialog',{attrs:{"isDisabled":_vm.disabledLogOut(item),"type":"icon","small":"","entity":item,"title":_vm.$t('transfer'),"loadData":_vm.loadData,"user":_vm.user}})],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.listAttendency,"tagActive":"active","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.attendency.channelType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.attendency.channelType === "C" ? " WebChat" : item.attendency.channelType === "W" ? "Whatsapp" : item.attendency.channelType === "T" ? "Telegram" : ""))]),_c('v-icon',[_vm._v(" "+_vm._s(item.attendency.channelType === "C" ? "mdi-chat-processing-outline" : item.attendency.channelType === "W" ? "mdi-whatsapp" : item.attendency.channelType === "T" ? "mdi-navigation-variant-outline" : " ")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"div-actions",attrs:{"data-app":""}},[_c('TransferDialog',{attrs:{"attendency":_vm.attendencyToTransferDialog,"conversation":item,"type":"icon","small":"","title":_vm.$t('transfer'),"user":_vm.user,"loadData":_vm.loadDetails}}),_c('ManagerChat',{attrs:{"user":_vm.user,"conversation":item}}),_c('CloseDialog',{attrs:{"type":"icon","small":"","entity":item,"title":_vm.$t('transfer'),"user":_vm.user,"loadData":_vm.loadDetails,"attendency":_vm.attendencyToTransferDialog}})],1)]}}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }