











































import { Component } from "vue-property-decorator";
import PlanInput from "./PlanInput.vue";
import { IPlan, IProductList } from "../../types/Plan";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { paths } from "../../services/apiPaths";
import ConfirmDelete from "../../components/ConfirmDelete.vue";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { format, parseISO } from "date-fns";
import { Breadcrumb } from "../../types/breadcrumb";
import { HeaderItem } from "../../types/headeritem";

@Component({
  components: {
    PlanInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class PlanList extends Request {
  productList: IProductList[] = [];
  productId: number = null;
  rerender: number = 1;
  path: string = paths.heppyInvoicePlans;

  page = {
    title: `${translate("title.plans")}`,
    icon: null,
  };

  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("title.plans")}`,
      disabled: true,
    },
  ];

  header: HeaderItem[] = [
    { text: `${translate("planName")}`, align: "start", value: "name" },
    { text: `${translate("price")}`, align: "center", value: "price" },
    {
      text: `${translate("dateRegister")}`,
      align: "center",
      value: "updatedAt",
    },
    { text: `${translate("active")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-notebook",
    titlePage: `${translate("title.plans")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  list: IPlan[] = [];

  async loadData() {
    this.path = this.paths.heppyInvoicePlans;
    const product = await this.get(this.paths.productPath);
    this.productList = product[0];
    this.productList.map((product) => {
      this.productId = product.id;
    });
    const result = await this.get(this.paths.heppyInvoicePlans);

    result[0].map((product) => {
      product.updatedAt = format(
        parseISO(product.updatedAt),
        "dd/MM/yyyy HH:mm"
      );
      product.price = "R$ " + product.price;
    });
    this.list = result[0];
    this.rerender += 1;
  }
  async mounted() {
    this.loadData();
  }
}
