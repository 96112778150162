

































































































































import { Component, Prop } from "vue-property-decorator";
import { IPlan } from "../../types/Plan";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import {IUser} from "../../types/User";
import { IPlanDetails } from "../../types/Plan";
@Component({
  components: {
    InputPopup,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
  directives: { mask },
})
export default class PlanInput extends Request {
  @Prop() entity: IPlan;
  @Prop() list: IPlan[];
  @Prop() loadData: () => void;
  @Prop() productId: number;
  dialog: boolean = false;
  header = `${translate("plan")}`;

  getUser: () => IUser;
  listPlanDetails: IPlanDetails[] = [];

  localEntity: IPlan = {};
  editorUserId: number = null;
  load(): void {
    if (this.entity) {
      this.localEntity = { ...this.entity };
    }
  }

  async onSave() {
    if (this.listPlanDetails && this.listPlanDetails.length > 0) {
      const planDetail = this.listPlanDetails.map(function (x) {
        return {
          id: x.id,
          key: x.key,
          value: x.value,
        };
      });
      this.localEntity.planDetails = planDetail;
    }
    this.localEntity.editorUserId = this.editorUserId;
    this.localEntity.updatedAt = new Date();

    this.localEntity.productId = Number(this.productId);
    const result = await this.save(
      this.paths.heppyInvoicePlansSave,
      this.localEntity
    );
    if (result.primaryOffer === true) {
      this.$message.successMessage("Oferta Principal Alterada");
    }
    this.localEntity.primaryOffer = result.primaryOffer;

    this.loadData();

    // for (let index = 0; index < this.list.length; index++) {
    //   this.localEntity = this.list[index];
    // }

    return result;
  }
  async saveAndClear() {
    const result = await this.onSave();
    if (!this.entity.id) {
      this.listPlanDetails = [];
      this.listPlanDetails.push({
        name: "",
        value: "",
      });
    }
    this.dialog = false;

    return result;
  }
  logicDelete(index) {
    if (index > -1) {
      this.listPlanDetails.splice(index, 1);
    }
  }
  addComponent(): void {
    this.listPlanDetails.push({
      name: "",
      value: "",
    });
  }
  async mounted() {
    this.load();
    const user = this.getUser();
    this.editorUserId = user.id;
    if (this.entity.id) {
      if (this.entity.planDetails) {
        this.listPlanDetails = this.entity.planDetails;
      }
    }
  }
  get missingName() {
    return !this.localEntity.name;
  }
  get missingPrice() {
    return !this.localEntity.price;
  }
  get saveDisabled() {
    return this.missingName || this.missingPrice;
  }
}
