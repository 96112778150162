

























































































































































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import MaintenanceInput from "./MaintenanceInput.vue";
import { translate } from "../../plugins/i18n/index";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import moment from "moment";
@Component({
  components: {
    MaintenanceInput,
    InputPopup,
  },
})
export default class ChangeStatus extends Request {
  @Prop() entity: any;
  @Prop() readonly manutentionId: number;
  selectedStatus: any = null;
  observation: string = "";
  reschedule: boolean = false;
  rescheduleLabel = this.$t("rescheduleMessage");
  closeDialog() {
    return this.$emit("close-dialog", false);
  }
  statusList = [
    {
      description: `${translate("toBePerformed")}`,
      id: 1,
    },
    {
      description: `${translate("succssesfullyDone")}`,
      id: 2,
    },
    {
      description: `${translate("partiallyDone")}`,
      id: 3,
    },
    {
      description: `${translate("Unrealized")}`,
      id: 4,
    },
  ];
  productList: any = [];
  selectedProduct: any = null;
  date: any = null;
  menu = false;
  startDateTime = null;
  startDateMenu = false;
  endDateTime = null;
  endtDateMenu = false;
  requester: any = "";
  technicalManagerList: any = [];
  technicalManager: any = null;
  authorizations: any = null;
  authorizationsList: any = [];
  explanation: string = "";
  risk: string = "";
  ascUser: string = "";
  ascOs: string = "";
  ticketMatrix: any = null;
  clientUser: any = null;
  description: string = "";

  async mounted() {
    if (this.selectedStatus === 4) {
      this.reschedule = true;
    }
    if (this.entity.id) {
      this.date = moment(new Date(this.entity.date))
      this.startDateTime = this.entity.initialTime;
      this.endDateTime = this.entity.finalTime;
      this.technicalManager = this.entity.executor;
      this.selectedProduct = this.entity.productId;
      this.selectedStatus = this.entity.status;
      this.observation = this.entity.statusObservation;
      this.explanation = this.entity.explanation;
      this.risk = this.entity.risk;
      this.ascUser = this.entity.ascUser;
      this.ascOs = this.entity.ascOs;
      this.ticketMatrix = this.entity.ticketMatrix;
      this.clientUser = this.entity.clientUser;
      this.description = this.entity.description;
      const result = await this.get(this.paths.maintenancePath);
      this.authorizations = result[0].find(
        (i) => i.id === this.manutentionId
      ).manutentionAuthorization;

      this.authorizations = this.authorizations.map(
        (item) => item.authorizationUserId
      );
    }
    const user = this.$store.getters["auth/getUser"];
    this.requester = user.firstName;
    const product = await this.get(this.paths.productPath);
    this.productList = product[0];

    const users = await this.get(this.paths.userPath);
    this.technicalManagerList = users[0];

    const authorizations = await this.get(this.paths.userPath);
    this.authorizationsList = authorizations[0];
  }
  async onSave()  {
    const user = this.$store.getters["auth/getUser"];
    const requester = user.id;

    const localEntity = {
      id: this.entity.id,
      manutentionId: this.manutentionId,
      productId: this.selectedProduct,
      date: this.date,
      initialTime: this.startDateTime,
      finalTime: this.endDateTime,
      requester: requester,
      executor: this.technicalManager,
      statusObservation: this.observation,
      description: this.description,
      explanation: this.explanation,
      risk: this.risk,
      ascUser: this.ascUser,
      ascOs: this.ascOs,
      ticketMatrix: this.ticketMatrix,
      clientUser: this.clientUser,
      status: this.selectedStatus,
    };
    this.entity.statusObservation = this.observation;
    this.entity.status = this.selectedStatus;

    const result = await this.save(this.paths.maintenancePath, localEntity);
    // this.authorizations.map(async (item) => {
    //   const entityAuthorization = {
    //     manutentionId: result.id,
    //     authorizationUserId: item,
    //   };
    //   return await this.save(
    //     this.paths.maintenanceAuthorizationPath,
    //     entityAuthorization
    //   );
    // });
    this.updateStatus();
    this.$emit("update-dialog", false);
    return result;
  }
  async updateStatus() {
    this.entity.statusObservation = this.observation;
    this.entity.status = this.selectedStatus;
    const result = await this.save(this.paths.maintenancePath, this.entity);
    return result;
  }
}
