










































































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { InputModel } from "@matrixbrasil/matrix-lib-crud-frontend/src/types/InputModel";
import { InputPopup } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import moment from "moment";
@Component({
  components: {
    InputPopup,
  },
})
export default class MaintenanceInput extends Request {
  @Prop() readonly entity: any;
  @Prop() readonly list: any[];
  @Prop() readonly manutentionId: number;
  @Prop() loadData: any;

  readonly header = `${translate("maintenance")}`;
  productList: any = [];
  selectedProduct: any = null;
  date: any = null;
  menu = false;
  startDateTime: string = "";
  startDateMenu: string = "";
  endDateTime: string = "";
  endtDateMenu = false;
  requester: any = "";
  technicalManagerList: any = [];
  technicalManager: any = null;
  authorizations: any = null;
  authorizationsList: any = [];
  async onSave()  {
    if (!this.entity.id) {
      const user = this.$store.getters["auth/getUser"];
      const requester = user.id;
      this.entity.productId = this.selectedProduct;
      this.entity.date = this.date;
      this.entity.initialTime = this.startDateTime;
      this.entity.finalTime = this.endDateTime;
      this.entity.requester = requester;
      this.entity.executor = this.technicalManager;
      this.entity.status = 1;

      this.entity.manutentionAuthorization = {
        authorizationUserId: this.authorizations,
      };

      const result = await this.save(
        this.paths.maintenanceSavePath,
        this.entity
      );

      return result;
    } else {
      const user = this.$store.getters["auth/getUser"];
      const requester = user.id;
      this.entity.productId = this.selectedProduct;

      this.entity.initialTime = this.startDateTime;
      this.entity.finalTime = this.endDateTime;
      this.entity.requester = requester;
      this.entity.executor = this.technicalManager;
      this.entity.status = 1;
      this.entity.manutentionAuthorization = {
        authorizationUserId: this.authorizations,
      };

      const result = await this.save(
        this.paths.maintenanceUpdatePath,
        this.entity
      );

      return result;
    }
  }

  async mounted() {
    if (this.entity.id) {
      this.date = moment(new Date(this.entity.date)).format("DD/MM/YYYY");
      this.startDateTime = this.entity.initialTime;
      this.endDateTime = this.entity.finalTime;
      this.technicalManager = this.entity.executor.id;
      this.selectedProduct = this.entity.productId;
      const result = await this.get(this.paths.maintenancePath);
      this.authorizations = result[0].find(
        (i) => i.id === this.manutentionId
      ).manutentionAuthorization;

      this.authorizations = this.authorizations.map(
        (item) => item.authorizationUserId
      );
    }
    const user = this.$store.getters["auth/getUser"];
    this.requester = user.firstName;
    const product = await this.get(this.paths.productPath);
    this.productList = product[0];

    const users = await this.get(this.paths.userPath);
    this.technicalManagerList = users[0];

    const authorizations = await this.get(this.paths.userPath);
    this.authorizationsList = authorizations[0];
  }

  itemsForm: InputModel[] = [
    {
      id: 1,
      col: 3,
      slot: "product",
    },
    {
      id: 2,
      col: 3,
      slot: "date",
    },
    {
      id: 3,
      col: 3,
      slot: "startTime",
    },
    {
      id: 4,
      col: 3,
      slot: "endTime",
    },
    {
      id: 5,
      col: 12,
      label: `${translate("description")}`,
      tag: "v-textarea",
      type: "text",
      model: "description",
    },
    {
      id: 6,
      col: 6,
      label: `${translate("explanation")}`,
      tag: "v-textarea",
      type: "text",
      model: "explanation",
    },
    {
      id: 7,
      col: 6,
      label: `${translate("scratchs")}`,
      tag: "v-textarea",
      type: "text",
      model: "risk",
    },
    {
      id: 8,
      col: 6,
      slot: "requester",
    },
    {
      id: 9,
      col: 6,
      slot: "technicalManager",
    },
    {
      id: 10,
      col: 6,
      label: `${translate("devResponsable")}`,
      tag: "v-text-field",
      type: "text",
      model: "ascUser",
    },
    {
      id: 11,
      col: 6,
      label: `${translate("devOs")}`,
      tag: "v-text-field",
      type: "text",
      model: "ascOs",
    },
    {
      id: 12,
      col: 6,
      label: `${translate("ticketMatrix")}`,
      tag: "v-text-field",
      type: "number",
      model: "ticketMatrix",
    },
    {
      id: 13,
      col: 6,
      label: `${translate("clientResponsable")}`,
      tag: "v-text-field",
      type: "text",
      model: "clientUser",
    },
    {
      id: 14,
      col: 12,
      slot: "authorizations",
    },
  ];
}
