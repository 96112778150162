




























































































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../../../services/request";
import TransferDialog from "../../../chat/TransferDialog.vue";
import CloseDialog from "../../../../components/CloseDialog.vue";
import ActiveDialog from "../../../../components/ActiveDialog.vue";
import { translate } from "../../../../plugins/i18n/index";
import { mapGetters } from "vuex";
import ManagerChat from "./ManagerChat.vue";
import { PageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { HeaderItem } from "../../../../types/headeritem";
import { IUser } from "../../../../types/User";
@Component({
  components: {
    List,
    TransferDialog,
    BaseBreadcrumb,
    ActiveDialog,
    CloseDialog,
    ManagerChat,
    PageHeader,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class NoQueueOrAgente extends Request {
  title: string = `${translate("manager")}`;
  list: any[] = [];
  search: string = "";
  getUser!: () => IUser;
  user: IUser = {};
  itemsPerPage: number = 10;
  type: string = "";
  customColumns = [
    {
      slotName: "attendency.channelType",
      itemValue: "attendency.channelType",
    },
  ];
  header: HeaderItem[] = [
    {
      text: `${translate("client")}`,
      align: "start",
      value: "contact.firstName",
    },
    {
      text: `${translate("protocol")}`,
      align: "center",
      value: "attendency.protocolNumber",
    },
    {
      text: `${translate("channelSingular")}`,
      align: "center",
      value: "attendency.channelType",
    },

    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "start",
      sortable: false,
      width: "15%",
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-account-multiple-remove",
    titlePage: `${translate("noAueueOrAgent")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  mounted() {
    this.loadData();
  }
  async loadData() {
    this.user = this.getUser();
    const listWaiting = await this.get(this.paths.listAttendencyPath, {
      take: this.itemsPerPage,
      skip: 1,
    });
    this.list = listWaiting[0].filter((i) => !i.endDate);
  }

  get listWaiting() {
    return this.list;
  }
}
