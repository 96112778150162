




















import { Component } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import AgentInput from "./AgentInput.vue";
import { IUser } from "../../../types/User";
import Request from "../../../services/request";
import { translate } from "../../../plugins/i18n/index";
import { paths } from "../../../services/apiPaths";
import { ConfirmDelete, BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
@Component({
  components: {
    AgentInput,
    List,
    ConfirmDelete,
    BaseBreadcrumb,
  },
})
export default class UserList extends Request {
  deletePath = "";

  companyId = this.$route.query.companyId;
  page = {
    title: `${translate("title.userList")}`,
    icon: null,
  };

  breadcrumbs = [
    {
      text: `${translate("title.company")}`,
      to: "company-list",
      disabled: false,
    },
    {
      text: `${translate("title.userList")}`,
      disabled: true,
    },
  ];

  header = [
    { text: `${translate("name")}`, align: "start", value: "firstName" },
    { text: `${translate("email")}`, align: "center", value: "email" },
    { text: `${translate("phone")}`, align: "center", value: "phone" },
    { text: `${translate("active")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];

  pageHeader: TPageHeader = {
    mainIcon: "mdi-account-multiple-plus",
    titlePage: `${translate("users")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  async send(item: any) {
    return await this.post(this.paths.forgotPasswordPath, { email: item }).then(() => {
      this.$message.succesMessage("Confirme seu cadastro no e-mail informado!");
    });
  }

  list: IUser[] = [];

  async mounted()  {
    this.companyId = this.$route.query.companyId;
    this.deletePath = paths.userPath;
    const result = await this.get(this.paths.userPath, {
      companyId: this.companyId,
    });

    if (result) {
      this.list = result[0];
    }
  }
}
