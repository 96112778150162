










































































































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import { ManagerI, ManagerITwo, ManagerAgente } from "../../../types/Manager";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../../services/request";
import TransferDialog from "../../chat/TransferDialog.vue";
import CloseDialog from "../../../components/CloseDialog.vue";
import ActiveDialog from "../../../components/ActiveDialog.vue";
import { translate } from "../../../plugins/i18n/index";
import AttendancyQueueList from "./lists/AttendancyQueueList.vue";
import AttendancyAgenteList from "./lists/AttendancyAgenteList.vue";
import NoAueueOrAgent from "./lists/NoAueueOrAgent.vue";
import { Breadcrumb } from "../../../types/breadcrumb";
import { HeaderItem } from "../../../types/headeritem";
import { ITypeList } from "../../../types/manager/manager";
@Component({
  components: {
    List, 
    TransferDialog,
    BaseBreadcrumb,
    ActiveDialog,
    CloseDialog,
    AttendancyAgenteList,
    AttendancyQueueList,
    NoAueueOrAgent,
  },
})
export default class Manager extends Request {
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("manager")}`,
      disabled: true,
    },
  ];
  search:string=''
  title: string = `${translate("manager")}`;
  listAgente: ManagerAgente[] = [];
  list: ManagerITwo[] = [];
  listQueue: ManagerI[] = [];
  type: string = "";
  headerAgente = [
    { text: `${translate("name")}`, align: "start", value: "name" },
    { text: `${translate("status")}`, align: "center", value: "active" },
    {
      text: `${translate("amountService")}`,
      align: "center",
      value: "amountService",
    },
    { text: "", value: "data-table-expand" },
  ];

  typeList: ITypeList[] = [
    {
      id: 1,
      name: translate("queue"),
      value: "queue",
    },
    {
      id: 2,
      name: translate("agentList"),
      value: "agente", 
    },
    {
      id: 3,
      name: translate("noAueueOrAgent"), 
      value: "noAueueOrAgent",
    },
  ];
  header: HeaderItem[] = [
    { text: `${translate("client")}`, align: "start", value: "client" },
    { text: `${translate("lastSend")}`, align: "center", value: "lastSend" },
    {
      text: `${translate("lastMessage")}`,
      align: "center",
      value: "lastMessage",
    },
    { text: `${translate("protocol")}`, align: "center", value: "protocol" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  headerQueue: HeaderItem[] = [
    { text: `${translate("queue")}`, align: "start", value: "queue" },
    {
      text: `${translate("amountServiceBot")}`,
      align: "center",
      value: "amountServiceBot",
    },
    {
      text: `${translate("amountServiceAgente")}`,
      align: "center",
      value: "amountServiceAgente",
    },
    {
      text: `${translate("amountServiceWaiting")}`,
      align: "center",
      value: "amountServiceWaiting",
    },
    {
      text: `${translate("amountServiceAllDay")}`,
      align: "center",
      value: "amountServiceAllDay",
    },

    { text: "", value: "data-table-expand" },
  ];
  pageHeaderQueue: TPageHeader = {
    mainIcon: "mdi-human-queue",
    titlePage: `${translate("queue")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  pageHeadeAgente: TPageHeader = {
    mainIcon: "mdi-account-circle-outline",
    titlePage: `${translate("agentList")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  storeType(value) {
    this.type = value;
  }
  async mounted() {
    const typeResult = localStorage.getItem("type");
    if (typeResult === "queue") {
      this.type = "queue";
    } else {
      this.type = "agente";
    }
  }
}
