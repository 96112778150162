





































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../../services/request";
import { paths } from "../../../../services/apiPaths";
import { io } from "socket.io-client";
import { translate } from "../../../../plugins/i18n/index";
import env from "../../../../services/env";
import ChatBody from "../../../chat/ChatBody.vue";
import { mapGetters } from "vuex";
import { IUser } from "../../../../types/User";
import { IAttendencyList } from "../../../../types/chat/chat-attendency";
@Component({
  components: { ChatBody },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Chat extends Request {
  @Prop() conversation: IAttendencyList;
  header: string = `${translate("managerVision")}`;

  dialog: boolean = false;
  rerender: number = 1;
  rerenderNotification: number = 1;
  socketUrl: string = `${env.protocol}${env.socket}`;
  socketWhatsappUrl: string = `${env.protocol}${env.socketWhatsapp}`;
  socket = io(this.socketUrl, {
    path: `${paths.socketPath}`,
    transports: ["websocket", "polling"],
    reconnection: true,
  });
  fromMe = [];

  getUser!: () => IUser;
  user: IUser = {};
  socketWhatsapp = io(this.socketWhatsappUrl, {
    path: `${paths.socketWhatsappPath}`,
    transports: ["websocket", "polling"],
    reconnection: true,
  });
  itemsPerPage: number = 10;
  iconSent = {};
  itemsPerPageMessagesWhatsapp: number = 100;

  mounted() {
    if (this.conversation?.id) {
      this.loadData();
      this.conversation.messages = [];
      this.conversation.randomNumber = 1;
      this.chargeWhatsapp(this.conversation);
    }
  }
  async loadData() {
    this.socketWhatsappUrl = `${env.protocol}${env.socketWhatsapp}`;
    this.socketUrl = `${env.protocol}${env.socket}`;
    this.user = this.getUser();
  }
  created() {
    this.listeningSocket();
  }
  updateMessage(message) {
    if (message.userName === this.user.firstName) {
      this.fromMe[message.userName] = true;
    } else {
      this.fromMe[message.userName] = false;
    }
    this.conversation.messages.push(message);

    this.rerender += 1;
  }
  async listeningSocket() {
    this.socket.on("connect", () => {
      this.socket.on("message", (message) => {
        const { userName } = message;
        const { firstName } = this.user;
        this.fromMe[userName] = userName === firstName ? true : false;

        if (
          `${this.conversation.contact.firstName}${this.conversation.contact.email}` ===
          message.room
        ) {
          this.conversation.notification =
            this.conversation.attendency.unreadMessages;
          message.typeMessage = "text";
          if (
            this.conversation.attendency &&
            this.conversation.attendency.lastMessage
          ) {
            this.conversation.attendency.lastMessage = message.text;
          }
          this.conversation.messages.push(message);

          this.rerender++;
        }
      });
    });

    const messageIcons = {
      sent: {
        icon: "mdi-check",
        color: "#515354",
      },
      delivered: {
        icon: "mdi-check-all",
        color: "#515354",
      },
      read: {
        icon: "mdi-check-all",
        color: "#00b2ff",
      },
    };

    this.socketWhatsapp.on("connect", () => {
      this.socketWhatsapp.on("whatsapp", async (message) => {
        const {
          userName,
          typeMessage,
          protocolNumber,
          messageId,
          unreadMessages,
          text,
        } = message;
        const channel = await this.get(
          this.paths.channelPath + this.conversation.attendency.channelId
        );
        const { messages } = this.conversation;
        const { firstName } = this.user;
        this.fromMe[userName] = userName === firstName ? true : false;

        switch (typeMessage) {
          case "text":
          case "image":
          case "video":
          case "audio":
          case "location":
          case "contacts":
            if (
              channel.active &&
              this.conversation.attendency.protocolNumber === protocolNumber
            ) {
              this.conversation.notification = unreadMessages;
              this.rerenderNotification++;

              if (this.conversation.attendency.lastMessage) {
                this.conversation.lastMessage = text;
                this.rerenderNotification++;
              }

              this.conversation.messages.push(message);
              this.rerender++;
              this.playSound();
            }

            break;
          case "sent":
          case "delivered":
          case "read":
            messages.forEach((msg) => {
              if (msg.messageId === messageId) {
                msg.iconSent = messageIcons[typeMessage];
              }
            });
            break;
          default:
            break;
        }

        this.rerender++;
      });
    });
  }
  async chargeWhatsapp(conversation: IAttendencyList) {
    this.socketWhatsapp.emit("room", {
      protocolNumber: conversation?.attendency?.protocolNumber,
    });

    if (conversation.randomNumber === 1) {
      conversation.messages = [];

      const messages = await this.get(
        `${this.paths.archiveWhatsappPath}?contactId=${
          conversation?.contact.id
        }&skip=${1}&take=${this.itemsPerPageMessagesWhatsapp}`
      );
      const messagesList = messages.result
        ? messages.result.reverse()
        : messages.reverse();

      messagesList.forEach((message) => {
        const { userName, typeMessage, protocolNumber, messageId } = message;
        const { attendency, messages } = conversation;
        const { firstName } = this.user;

        const messageIcons = {
          sent: { icon: "mdi-check", color: "#515354" },
          delivered: { icon: "mdi-check-all", color: "#515354" },
          read: { icon: "mdi-check-all", color: "#00b2ff" },
        };

        this.fromMe[userName] = userName === firstName;

        switch (typeMessage) {
          case "text":
          case "location":
            if (
              attendency &&
              attendency.id &&
              attendency.protocolNumber === protocolNumber
            ) {
              message.message = message.message.replace(/"/g, "");
              messages.push(message);

              this.rerender++;
            }
            break;
          case "contacts":
            if (
              attendency &&
              attendency.id &&
              attendency.protocolNumber === protocolNumber
            ) {
              messages.push(message);

              this.rerender++;
            }
            break;
          case "image":
          case "video":
          case "document":
          case "audio":
            if (
              attendency &&
              attendency.id &&
              attendency.protocolNumber === protocolNumber
            ) {
              message.message = message.message
                .slice(1)
                .replace(/"/g, "")
                .replace(/=/g, "");
              messages.push(message);
              this.rerender++;
            }
            break;

          case "sent":
          case "delivered":
          case "read":
            messages.forEach((msg) => {
              if (msg.messageId === messageId) {
                msg.iconSent = messageIcons[typeMessage];
              }
            });
            break;

          default:
            break;
        }

        this.rerender++;
        conversation.randomNumber += 1;
      });
    }
  }
  async chargeWebChat(conversation: IAttendencyList) {
    await this.patch(
      this.paths.attendencyPath + this.conversation.attendency.id,
      {
        unreadMessages: 0,
      }
    );
    this.socket.emit("select_room", {
      userName: this.user.firstName,
      room: `${conversation.contact.firstName}${conversation.contact.email}`,
      email: conversation.contact.email,
      companyId: conversation.attendency.companyId,
      channelId: conversation.attendency.channelId,
      editorUserId: this.user.id,
    });

    this.socket.once("archive", (archive) => {
      archive.map((item) => {
        item.typeMessage = "text";
        if (item.userName === this.user.firstName) {
          this.fromMe[item.userName] = true;
        } else {
          this.fromMe[item.userName] = false;
        }
      });

      this.conversation.messages = [...this.conversation.messages, ...archive];
      this.rerender++;
    });
    if (this.conversation.messages.length > 0) {
      this.socket.off("archive");
    }
  }
  async openMessages(conversation: IAttendencyList) {
    this.conversation = conversation;
    if (conversation.attendency.channelType === "C") {
      this.chargeWebChat(conversation);
    }

    if (conversation.attendency.channelType === "W") {
      await this.patch(
        this.paths.attendencyPath + this.conversation.attendency.id,
        {
          unreadMessages: 0,
        }
      );

      this.chargeWhatsapp(conversation);

      conversation.notification = 0;
    }
  }
  resolveChatIcon(iconType: string) {
    switch (iconType) {
      case "C":
        return "mdi-chat";
      case "W":
        return "mdi-whatsapp";
      case "T":
        return "mdi-telegram";
      default:
        throw new Error("Invalid icon type: " + iconType);
    }
  }
  get iconType() {
    return this.iconSent;
  }
  playSound() {
    const notificationSound = require("../../../../assets/bel.wav");
    var audio = new Audio(notificationSound);
    audio.play();
  }
}
