



















































import { Component } from "vue-property-decorator";
import { BaseBreadcrumb } from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import ConfirmDelete from "../../../components/ConfirmDelete.vue";
import DeleteWithChildren from "../../../components/DeleteWithChildren.vue";
import { List } from "@matrixbrasil/matrix-lib-crud-frontend/src/views";
import UserInput from "./UserInput.vue";
import { IUser } from "../../../types/User";
import { IProfile } from "../../../types/profile/ProfileType";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/components/header/PageHeader";
import Request from "../../../services/request";
import { translate } from "../../../plugins/i18n/index";
import { mapGetters } from "vuex";
import { Entity } from "../../../types/manager/user";
import { HeaderItem } from "../../../types/headeritem";
import { Breadcrumb } from "../../../types/breadcrumb";
@Component({
  components: {
    List,
    UserInput,
    BaseBreadcrumb,
    ConfirmDelete,
    DeleteWithChildren,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class AgentManagementList extends Request {
  deletePath: string = "";
  entity: Entity = {
    login: "teste",
    isAgent: true,
  };

  title: string = `${translate("users")}`;
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("users")}`,
      disabled: true,
    },
  ];

  header: HeaderItem[] = [
    { text: `${translate("firstName")}`, align: "start", value: "firstName" },
    { text: `${translate("lastName")}`, align: "center", value: "lastName" },
    { text: `${translate("phone")}`, align: "center", value: "phone" },
    { text: `${translate("active")}`, align: "center", value: "active" },
    {
      text: `${translate("actions")}`,
      value: "actions",
      align: "center",
      sortable: false,
      width: "15%",
    },
  ];
  isCharged: boolean = false;
  profileList: IProfile[] = [];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-account",
    titlePage: `${translate("users")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };

  list: IUser[] = [];

  async exampleCombinedGetRequest() {
    const paths = [
      this.paths.userByCompanyPath,
      this.paths.profilePath,
      this.paths.userAgentPath,
    ];

    try {
      const result = await this.combineGetRequests(paths);

      this.list = result[0][0];
      this.profileList = result[1][0];
      this.list.map((item) => {
        item.users = [];
        result[2][0].map((user) => {
          if (user.userId === item.id) {
            item.users.push(user.user);
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
  async loadData()  {
    this.exampleCombinedGetRequest();
    this.isCharged = false;
    this.deletePath = this.paths.userPath;

    this.isCharged = true;
  }
  mounted() {
    this.loadData();
  }
}
